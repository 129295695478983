<script setup lang="ts">
import { PhAddressBook, PhCaretRight, PhPackage } from '@phosphor-icons/vue';
import { createReusableTemplate } from '@vueuse/core';
const [UseTemplate, Separator] = createReusableTemplate();

const logan = useLoganStore();
const { firstName, lastName } = storeToRefs(logan);
const avatarFallBack = computed(() => {
	if (!firstName.value || !lastName.value) {
		return 'LU';
	} else {
		return `${firstName.value[0].toUpperCase()}${lastName.value[0].toUpperCase()}`;
	}
});
</script>

<template>
	<UseTemplate>
		<div class="my-3xl h-[1px] bg-gray-200 hidden lg:block"></div>
	</UseTemplate>

	<div class="flex flex-col w-full">
		<!-- Profile -->
		<NuxtLink to="/profile" class="lg:flex gap-xl items hidden">
			<Avatar size="sm" shape="circle">
				<AvatarImage src="" alt="User avatar" />
				<AvatarFallback>{{ avatarFallBack }}</AvatarFallback>
			</Avatar>
			<div class="grow">
				<h1 class="text-sm font-semibold text-gray-900">Profile</h1>
				<h4 class="text-xs text-gray-600">Manage your profile</h4>
			</div>
			<PhCaretRight size="20" />
		</NuxtLink>
		<Separator />

		<!-- Address Book -->
		<NuxtLink
			to="/address-book"
			class="flex gap-md lg:gap-xl items lg:p-0 py-md px-xl w-full"
		>
			<PhAddressBook size="20" />
			<div class="grow">
				<h1
					class="text-sm font-medium lg:font-semibold lg:text-gray-900 text-gray-700"
				>
					Address Book
				</h1>
				<h4 class="text-xs text-gray-600 hidden lg:block">
					Manage your saved addresses
				</h4>
			</div>
			<PhCaretRight class="hidden lg:block" size="20" />
		</NuxtLink>
		<Separator />

		<!-- Orders -->
		<NuxtLink
			to="/orders"
			class="flex gap-md lg:gap-xl items lg:p-0 py-md px-xl w-full"
		>
			<PhPackage size="20" />
			<div class="grow">
				<h1
					class="text-sm font-medium lg:font-semibold lg:text-gray-900 text-gray-700"
				>
					Orders
				</h1>
				<h4 class="text-xs text-gray-600 hidden lg:block">
					View and track your orders
				</h4>
			</div>
			<PhCaretRight class="hidden lg:block" size="20" />
		</NuxtLink>
	</div>
</template>
