<script setup lang="ts">
import { PhList } from '@phosphor-icons/vue';
import { IconButton } from '@laam/ui/base';
import MobileMenu from './MobileMenu.vue';
import type { OctaneHeaderData } from '@laam/cms-shared';
import CartButton from '../CartButton.vue';
import SearchButton from '../SearchButton.vue';

interface OctaneHeaderMobileProps {
	data: OctaneHeaderData;
}

const headerVisible = inject('headerVisible') as Ref<boolean>;

defineProps<OctaneHeaderMobileProps>();
</script>
<template>
	<div
		:data-header-visible="headerVisible"
		class="octane-header--mobile flex p-md lg:hidden sticky left-0 right-0 z-10 transition-all bg-white"
	>
		<IconButton size="nav-button" variant="icon">
			<MobileMenu :data="data" @menu-clicked="(href) => navigateTo(href)">
				<PhList />
			</MobileMenu>
		</IconButton>
		<IconButton size="nav-button" variant="icon">
			<PhList class="invisible" />
		</IconButton>

		<!-- Main Logo Area -->
		<NuxtLink
			to="/"
			class="aspect-[224/44] h-full logo flex items-center justify-center grow"
		>
			<NuxtImg
				v-if="data?.logo?.url"
				:src="data.logo.url"
				alt="logo"
				class="h-full"
			/>
		</NuxtLink>

		<!-- Right Area -->
		<SearchButton />
		<CartButton />
	</div>
</template>

<style scoped>
.octane-header--mobile[data-header-visible='true'] {
	top: 0;
}
.octane-header--mobile[data-header-visible='false'] {
	top: calc(0 - var(--octane-topbar-height-mobile));
}
</style>
