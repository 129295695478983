<script setup lang="ts">
import { Button, Popover, PopoverTrigger, PopoverContent } from '@laam/ui/base';

interface List {
	label: string;
	href: string;
}

defineProps<{ data: List[] }>();
const emit = defineEmits<{
	(e: 'menu-clicked', href: string): void;
}>();

const open = ref(false);

const handleMenuClick = (href: string) => {
	emit('menu-clicked', href);
	open.value = false;
};
</script>
<template>
	<Popover v-model:open="open">
		<PopoverTrigger as-child>
			<slot />
		</PopoverTrigger>
		<PopoverContent class="w-14xl">
			<div>
				<Button
					v-for="(item, index) in data"
					:key="index"
					variant="tertiary"
					class="block w-full my-xxs text-wrap flex-wrap"
					@click="handleMenuClick(item.href)"
				>
					{{ item.label }}
				</Button>
			</div>
		</PopoverContent>
	</Popover>
</template>
