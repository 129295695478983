<script setup lang="ts">
import {
	Sheet,
	SheetTrigger,
	SheetContent,
	Accordion,
	AccordionItem,
	AccordionTrigger,
	Button,
	AccordionContent,
} from '@laam/ui/base';
import type { OctaneHeaderData } from '@laam/cms-shared';
import { PhCaretRight, PhUserCircle } from '@phosphor-icons/vue';
import CurrencySelector from '../logan/CurrencySelector.vue';
import Logan from '../logan/Logan.vue';
import LoganInfo from '../navigation/mobile/LoganInfo.vue';
import AccountSettingsOptions from '../logan/AccountSettingsOptions.vue';

interface Props {
	data: OctaneHeaderData;
}
defineProps<Props>();
const emit = defineEmits<{
	(e: 'menu-clicked', href: string): void;
}>();

const LoganStore = useLoganStore();

const open = ref(false);

const handleNavigation = (href: string) => {
	emit('menu-clicked', href);
	open.value = false;
};
</script>
<template>
	<Sheet>
		<SheetTrigger as-child>
			<slot />
		</SheetTrigger>
		<SheetContent v-model:open="open" side="left">
			<div class="flex flex-col justify-between w-full h-full px-md">
				<Accordion
					type="single"
					class="inline-flex flex-col w-full py-md px-xl"
					collapsible
				>
					<template v-for="item in data.menubar" :key="item.href">
						<AccordionItem
							v-if="item.children?.length"
							:value="item.href"
							class="border-0"
						>
							<div class="flex">
								<Button
									variant="tertiary"
									class="justify-start grow !text-sm !font-medium !text-gray-700"
									size="sm"
									@click="handleNavigation(item.href)"
								>
									{{ item.name }}
								</Button>
								<AccordionTrigger></AccordionTrigger>
							</div>
							<AccordionContent class="inline-flex flex-col">
								<Button
									v-for="child in item.children"
									:key="child.href"
									size="sm"
									variant="tertiary"
									class="pl-10xl justify-start"
									@click="handleNavigation(child.href)"
								>
									{{ child.name }}
								</Button>
							</AccordionContent>
						</AccordionItem>
						<Button
							v-else
							:key="item.href"
							:href="item.href"
							size="sm"
							variant="tertiary"
							class="justify-start"
							@click="$emit('menu-clicked', item.href)"
						>
							{{ item.name }}
						</Button>
					</template>
				</Accordion>
				<div>
					<div
						class="mb-7xl flex flex-col items-start justify-start border-b border-gray-200"
					>
						<AccountSettingsOptions />
						<CurrencySelector v-slot="{ modelValue, methods }" type="sheet">
							<Button
								size="lg"
								variant="tertiary"
								class="font-semibold h-fit w-full !gap-sm py-md px-xl"
							>
								<div class="w-5xl h-5xl !max-w-[20px] min-w-[20px] lg:hidden">
									<NuxtImg
										:src="methods.getSelectedCountry(modelValue)!.flag"
										alt="country flag"
										loading="eager"
										width="10"
										class="w-full"
									>
									</NuxtImg>
								</div>
								<span class="text-left grow text-sm font-medium">
									{{ methods.getSelectedCountry(modelValue)!.name }}
								</span>
								<PhCaretRight />
							</Button>
						</CurrencySelector>
					</div>
					<template v-if="!LoganStore.isSignedIn">
						<Logan :show-confirmation-text="false" />
						<Button
							size="lg"
							variant="tertiary"
							class="font-semibold border-0 h-fit"
							@click="LoganStore.showLoginSheet = true"
						>
							<PhUserCircle />
							Sign In / Register
						</Button>
					</template>

					<LoganInfo v-else />
				</div>
			</div>
		</SheetContent>
	</Sheet>
</template>
