<script setup lang="ts">
import { OctaneFooter } from '@laam/ui/cms';
import CartButton from '~/components/CartButton.vue';
import { OctaneHeader } from '~/components/octane-header';
import { useDeviceType } from '~/utils/use-device-type';

const { deviceType } = useDeviceType();
const GlobalStore = useGlobalStore();

const topBannerReason = ref(false);
const CartStore = useCartStore();

provide('deviceType', deviceType);
provide('topBannerReason', topBannerReason);

const route = useRoute();

const hidden = computed(() => {
	return !!route.name?.toString().includes('checkout');
});

onMounted(async () => {
	if (CartStore.cartId === '') {
		await CartStore.createCart();
	} else {
		await CartStore.getCartById();
	}
});
</script>

<template>
	<div>
		<OctaneHeader
			v-if="GlobalStore.header && !hidden"
			:data="GlobalStore.header"
		>
			<template #cart>
				<CartButton />
			</template>
		</OctaneHeader>

		<div class="max-w-[1280px] mx-auto">
			<slot />
		</div>
		<OctaneFooter
			v-if="
				GlobalStore.footer &&
				!hidden &&
				!(isMobileView() && route.path.toLowerCase() === '/cart')
			"
			:data="GlobalStore.footer"
			:logo="GlobalStore.header?.logo?.url ?? undefined"
		/>
	</div>
</template>
