<script lang="ts" setup>
import type { OctaneFooterData } from '@laam/cms-shared';
import {
	FacebookIcon,
	InstagramIcon,
	MastercardIcon,
	TiktokIcon,
	WhatsappIcon,
	YoutubeIcon,
} from '../../../icons';

interface Props {
	data: OctaneFooterData;
	logo?: string;
}
defineProps<Props>();

const isSocialLinksEmpty = (links?: OctaneFooterData['socialLinks']) => {
	return Object.keys(links || {}).length === 0;
};
</script>

<template>
	<div class="bg-neutral-50 py-8xl">
		<div
			class="max-w-[1280px] px-8xl flex flex-col md:flex-row gap-11xl mx-auto"
		>
			<div class="flex flex-col justify-start gap-md flex-[4]">
				<NuxtImg
					v-if="!!logo"
					:src="logo"
					:alt="'LOGO'"
					loading="eager"
					class="w-fit"
				></NuxtImg>
				<p class="text-sm text-gray-600">
					{{ data.description }}
				</p>
			</div>
			<div class="flex flex-col md:flex-row gap-10xl flex-[6]">
				<div class="flex flex-col grow gap-md">
					<h1
						v-if="data.email || data.phone"
						class="text-sm font-semibold text-gray-400"
					>
						For Assistance
					</h1>
					<p v-if="!!data.email" class="text-gray-800 text-xs font-semibold">
						{{ data.email }}
					</p>
					<p v-if="!!data.phone" class="text-gray-800 text-xs font-semibold">
						{{ data.phone }}
					</p>
				</div>
				<div class="flex flex-col grow gap-md">
					<h1
						v-if="data.email || data.phone"
						class="text-sm font-semibold text-gray-400"
					>
						Help & Information
					</h1>
					<NuxtLink
						to="/terms-conditions"
						class="text-gray-800 text-xs font-semibold"
					>
						Terms & Conditions
					</NuxtLink>
					<NuxtLink
						to="/privacy-policy"
						class="text-gray-800 text-xs font-semibold"
					>
						Privacy Policy
					</NuxtLink>
				</div>
				<div
					v-if="!isSocialLinksEmpty(data.socialLinks)"
					class="flex flex-col grow gap-md"
				>
					<h1 class="text-sm font-semibold text-gray-400">Find us at</h1>
					<div class="flex gap-7xl">
						<NuxtLink
							v-if="data.socialLinks?.facebook"
							:to="data.socialLinks.facebook"
							:target="'_blank'"
						>
							<FacebookIcon />
						</NuxtLink>
						<NuxtLink
							v-if="data.socialLinks?.instagram"
							:to="data.socialLinks.instagram"
							:target="'_blank'"
						>
							<InstagramIcon />
						</NuxtLink>
						<NuxtLink
							v-if="data.socialLinks?.whatsapp"
							:to="data.socialLinks.whatsapp"
							:target="'_blank'"
						>
							<WhatsappIcon />
						</NuxtLink>
						<NuxtLink
							v-if="data.socialLinks?.tiktok"
							:to="data.socialLinks.tiktok"
							:target="'_blank'"
						>
							<TiktokIcon />
						</NuxtLink>
						<NuxtLink
							v-if="data.socialLinks?.youtube"
							:to="data.socialLinks.youtube"
							:target="'_blank'"
						>
							<YoutubeIcon />
						</NuxtLink>
					</div>
				</div>
				<div v-else class="flex flex-col grow gap-md"></div>
				<div class="flex flex-col grow gap-md">
					<h1 class="text-sm font-semibold text-gray-400">We accept</h1>
					<MastercardIcon />
				</div>
			</div>
		</div>
	</div>
</template>
