<script setup lang="ts">
import { Button, Popover, PopoverContent, PopoverTrigger } from '@laam/ui/base';
import AccountSettingsOptions from './AccountSettingsOptions.vue';
import { useLoganStore } from '~/stores/logan';

const LoganStore = useLoganStore();

const handleSignOut = () => {
	LoganStore.signOut();
};
</script>

<template>
	<Popover>
		<PopoverTrigger as-child>
			<slot />
		</PopoverTrigger>
		<PopoverContent class="w-[380px]">
			<div class="p-7xl border-b border-gray-200">
				<AccountSettingsOptions />
			</div>
			<div class="p-7xl">
				<Button
					class="w-full"
					variant="primary"
					size="lg"
					@click="handleSignOut"
				>
					Sign Out
				</Button>
			</div>
		</PopoverContent>
	</Popover>
</template>
