<script setup lang="ts">
import Desktop from './Desktop.vue';
import { type OctaneHeaderData } from '@laam/cms-shared';
import Mobile from './Mobile.vue';

interface OctaneHeaderProps {
	data: OctaneHeaderData;
}
interface OctaneHeaderEmits {
	(e: 'search-clicked'): void;
}

const emit = defineEmits<OctaneHeaderEmits>();
defineProps<OctaneHeaderProps>();

const { deviceType } = useDeviceType();

const route = useRoute();

const renderMobileHeader = computed(() => {
	const enabledRoutes = [
		'identifier',
		'all-products',
		'nodes-handle',
		'brands-handle',
		'drops-handle',
	];
	if (enabledRoutes.includes(route.name as string)) {
		return true;
	}
	return false;
});
</script>

<template>
	<Mobile
		v-if="deviceType === 'mobile' && renderMobileHeader"
		:data="data"
		@search-clicked="emit('search-clicked')"
	>
	</Mobile>
	<Desktop v-else :data="data" @search-clicked="emit('search-clicked')">
	</Desktop>
</template>
